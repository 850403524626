import React from 'react';
import PropTypes from 'prop-types';
import Isvg from 'react-inlinesvg';

import styles from './toast.css';

export const ToastBox = ({ messages, type }) => {
  type = type || 'info';
  let icon = {
    info: <React.Fragment>&#x2713;</React.Fragment>,
    error: "!"
  };

  return (
    <div className={`${styles.toastBox} ${styles[type]}`}>
      <ul className={styles.message}>
        {
          messages.map(message => {
            return (
              <li key="message">
                <div className={styles.icon}>{icon[type]}</div>
                <div className={styles.text}>{message}</div>
              </li>
            );
          })
        }
      </ul>
      <Isvg src={require('./images/icons8-cross-mark.svg')} cacheGetRequests={true}/>
    </div>
  );
};
export const ToastBoxImg = ({ messages, type }) => {
  type = type || 'info';
  let icon = {
    info: <React.Fragment>&#x2713;</React.Fragment>,
    error: "!"
  };

  return (
    <div className={`${styles.toastBox} ${styles[type]}`}>
      <ul className={styles.message}>
        {
          messages.map(message => {
            return (
              <li key="message" style={{gridTemplateColumns:'auto'}}>
                <div style={{margin:'10px 0' , fontWeight:'bold'}}>Your selected theme : </div>
                <div className={styles.text}>
                <div
                style={{
                  border: "solid 1px #d4d4d4",
                  boxShadow: "0px 5px 20px #0000001a",
                }}
              >
                    <img src={require('../../../themes/assets/' + message.imgUrl + '.png')}
            style={{
              borderRadius: "10px",
              padding: "5px",
              width: "100%",
            }}
                    />
                 <div style={{ padding: "20px",textAlign: "center",display: "flex",alignItems: "center",justifyContent: "center"  , flexDirection: "column"}}>
                    <a
                      style={{
                        color: "#2b2b2b",    textOverflow: "ellipsis",backgroundColor: "#f1f1f1",padding: "10px",width: "100%",margin: "0px 10px",overflow: "hidden",fontSize: "12px",whiteSpace: "nowrap"
                      }}
                      href={"https://oichub.com/" + message.msg}
                      target="_blank"
                    >
                      {"https://oichub.com/" + message.msg}
                    </a>{" "}
                    <a
                      /* href={"https://oichub.com/" + message.msg}*/
                      style={{
                        padding: "10px",borderRadius: "3px",background: "rgb(255, 152, 16)",color: "rgb(240, 240, 240)",cursor: "pointer", marginTop: "10px"
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "https://oichub.com/" + message.msg
                        );
                      }}
                    >
                      Copy Link
                    </a>{" "}
                  
                  </div>
                    </div>
                </div>
              </li>
            );
          })
        }
      </ul>
      <Isvg src={require('./images/icons8-cross-mark.svg')} cacheGetRequests={true}/>
    </div>
  );
};

ToastBox.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string
};
ToastBoxImg.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string
};
